@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "theme";
@import "./app/themes/typography.less";

@disabled-color: #333333;

@disabled-bg: #f5f5f5;
@disabled-active-bg: #f5f5f5;
@disabled-color-dark: #f5f5f5;

@table-expanded-row-bg: white;
@collapse-content-bg: #f0f2f5;

// global
.pointer {
  cursor: pointer;
}

//normalization styles

.ant-carousel .slick-dots-bottom {
  bottom: 6px;
}

.ant-col-right {
  text-align: right;
}

.ant-col-center {
  text-align: center;
}

.ant-tag {
  padding: 0px 5px;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0px;
}

.cursor-cont {
  cursor: context-menu !important;
}

.tab-status {
  font-size: 10px !important;
  font-weight: bold;
  color: white !important;
  border: none !important;
  height: unset !important;
  text-transform: uppercase;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 3px !important;
  padding: 2px 5px;
}

.tab-status:before,
.status:hover:before,
.tab-status:after,
.status:hover:after {
  height: 0;
}

.tab-status:before,
.status:before {
  top: 0;
}

.tab-status:after,
.status:after {
  bottom: 0;
}

.tab-status:before,
.status:before,
.tab-status:after,
.status:after {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 50%;
  right: 0;
  z-index: -1;

  transition: all 0.3s ease;
}

.title-top-page {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--primary-color);
}

.sub-title-top-page {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

/* Resume */
.title-resume {
  color: var(--primary-7);
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 8px;
}

.sub-title-resume {
  font-weight: bold;
  font-size: 14px;
  color: var(--primary-7);
}

.desc-resume {
  margin-top: -15px;
  font-weight: unset;
}

/* Form */

/*Step*/
.content {
  padding: 25px;

  &__space {
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(223, 223, 223, 0.5);
    background: white;
    margin-bottom: 25px;
    margin-top: 25px;
  }

  &__group {
    //padding: 20px 40px;

    @media screen and (max-width: 600px) {
      //padding: 0px 10px;
    }
  }

  &__steps-content {
    margin-top: 50px;
    min-height: 200px;
  }

  &__steps-action {
    margin-left: 40px;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;

    button {
      margin-right: 10px;
    }
  }
}

.ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: rgba(0, 0, 0, 0.25);
}

.title-step-form {
  color: var(--primary-color);
  font-size: 25px !important;
  font-weight: 700;
}

.desc-title-form {
  font-size: 13px;
  color: grey;
  margin-bottom: 5px;
  margin-top: -5px;
}

.tag-status {
  margin-bottom: 16px;

  nz-tag {
    width: auto;
    height: 18px;
    font-size: 11px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    border: 0;
    cursor: context-menu;
  }
}

//-----------------------------
.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none;
}

.disabled-input {
  pointer-events: none;
  color: #f5f5f5;
  background-color: #f5f5f5;
}

.input-disabled {
  pointer-events: none;
  color: #000;
  background-color: #f5f5f5;
}

.disableEvent {
  pointer-events: none;
}

.hidden {
  display: none !important;
}

.text-right {
  text-align: right;
}

.content-card {
  border-radius: 4px;
  box-shadow: inset 0 -0.5px 0 0 #ebebeb;
  background-color: #ffffff;
}

.primary-input-label {
  font-family: Montserrat;
  font-size: 14px;
  // font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 6.5px;

  & > label > span {
    color: red;
  }
}

.primary-radio {
  nz-radio-group {
    label {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      color: #222222;
    }

    .ant-radio-wrapper {
      height: auto;
    }
  }

  .ant-radio {
    top: 7px;
  }

  .ant-radio-inner {
    height: 24px;
    width: 24px;
  }

  .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.9);
  }

  .ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type="radio"] {
    height: 24px;
    width: 24px;
  }
}

.primary-input-group {
  border-radius: 4px !important;
  border: solid 1px #e8e8e8 !important;

  .primary-input {
    border: none !important;
    border-radius: none !important;
  }

  .primary-textarea {
    border: none !important;
    border-radius: none !important;
  }
}

.primary-input {
  color: #333333;
  height: 50px !important;
  border-radius: 4px !important;
  border: solid 1px #e8e8e8 !important;
  width: 100%;
  padding: 0px 8px;
}

nz-input-number {
  padding: 8px !important;
}

.primary-datePicker {
  .ant-picker-input {
    color: #333333;
    height: 38px !important;
    //background-color: #ffffff !important;
  }

  .ant-calendar-picker-input {
    color: #333333;
    height: 50px !important;
    border-radius: 4px !important;
    //border: solid 1px #e8e8e8 !important;
    //background-color: #ffffff !important;
  }

  .ant-calendar-picker {
    width: 100% !important;
  }
}

.primary-dateRangePicker {
  height: 46px;
  border-radius: 4px;

  .ant-calendar-picker-input {
    color: #333333;
    height: 50px !important;
    border-radius: 4px !important;
    border: solid 1px #e8e8e8 !important;
    background-color: #ffffff !important;
  }

  .ant-calendar-range-picker-separator {
    margin-top: 9px;
  }
}

.primary-yearPicker {
  input {
    color: #333333;
    height: 38px !important;
    border-radius: 4px !important;
    background-color: #ffffff !important;
  }
}

.primary-timePicker {
  .ant-picker-input {
    color: #333333;
    height: 38px !important;
    border-radius: 4px !important;
    //background-color: #ffffff !important;
  }

  .ant-picker {
    width: 100% !important;
  }
}

textarea {
  width: 100%;
}

.primary-textarea {
  border-radius: 4px !important;
  border: solid 1px #e8e8e8 !important;
  background-color: #ffffff !important;
  resize: none !important;
}

.primary-checkbox {
  .ant-checkbox {
    width: 22px;
    height: 22px;
    top: 7px;
  }

  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
    border: 2px solid #d9d9d9;
    border-radius: 4px;
  }

  .ant-checkbox-checked {
    background-color: var(--primary-1) !important;
    border-radius: 4px !important;
  }

  .ant-checkbox-checked:focus {
    outline: 0 !important;
  }
}

.tab-input {
  height: 50px;
  width: 169px !important;

  .ant-input-group-addon:last-child {
    background-color: white;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #aaaaaa;
  }

  .ant-input-group .ant-input {
    height: 48px;
    text-align: right;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.85);
    border-radius: 4px 0 0 4px;
  }
}

.ant-radio-checked:focus {
  outline: none;
}

.ant-form label {
  font-size: 14px;
}

.primary-select.ant-select-multiple {
  nz-select-top-control {
    padding-top: 0px !important;
  }
}

.primary-select {
  background-color: #ffffff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #333333;
  width: 100%;

  nz-select-top-control {
    height: 40px !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    border: solid 1px #e8e8e8 !important;
    padding-top: 6px !important;

    nz-select-search {
      input {
        height: 36px !important;
      }
    }
  }

  .ant-select-selection__rendered {
    line-height: 40px;
    height: 40px;
  }

  .ant-select-selection--single {
    height: 40px;
  }

  .ant-select-arrow .ant-select-arrow-icon {
    color: #000;
    font-weight: 700;
    font-size: 14px;
  }
}

.secondary-select {
  background-color: #ffffff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  border-radius: 4px;
  color: #979797;

  .ant-select-selection {
    border-radius: 4px;
    border: solid 1px #e8e8e8;
  }

  .ant-select-arrow .ant-select-arrow-icon {
    color: #000;
    font-weight: 700;
    font-size: 12px;
  }
}

.primary-file-upload {
  nz-upload div {
    width: 100%;
  }

  button {
    text-align: left;

    i svg {
      height: 20px;
      width: 20px;

      path {
        fill: #aaaaaa;
      }
    }

    span {
      position: relative;
      bottom: 2px;
      margin-left: 15px;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #aaaaaa;
    }
  }
}

.primary-input-radio-button {
  .ant-radio-button-wrapper {
    border: 0px;
    border-radius: 2.5px;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #979797;
  }

  .ant-radio-button-wrapper-checked::before {
    opacity: 5;
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover {
    background-color: var(--primary-2);
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 2px;
    background-color: #f0f2f5 !important;
  }
}

.error {
  .form__label {
    color: red;
  }
}

.has-error .ant-select-selection,
.has-error .ant-input {
  border-color: red !important;
  background-color: #ff00000a !important;

  :focus {
    border-color: red !important;
  }

  :hover {
    border-color: red !important;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px var(--primary-1);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-4);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-10);
}

/***
*** BEGIN FORMS ***
***/

.form {
  &__label {
    color: #222222;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.29;
  }

  &__info {
    color: #222222;
    font-size: 11px;
    font-weight: 500;
    line-height: 1.64;
    padding-top: 8px;
  }
}

.ant-form-item {
  margin-bottom: 8px;
}

.ant-form-item-label {
  text-align: left;
}

/***
*** END FORMS ***
***/

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.8;
  font-family: Montserrat;
  color: #222222;
  background-color: #ffffff;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;

  overflow-x: hidden;

  app-root {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
  }
}

nz-form-item {
  display: block !important;
}

nz-form-label {
  display: block !important;
  margin-top: 6px;
  margin-bottom: 6px;
}

/**
* AND INPUIT
*/

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.85);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input {
  width: 100%;
}

.ant-input-affix-wrapper {
  //border: none;
  border-radius: 4px;
  padding: 0px 8px 0px 0px;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 4px 11px;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  //border: none;
  box-shadow: none;
}

/*
* BUTTONS
*/

.ant-btn {
  border-radius: 5px;
}

.ant-btn-round {
  padding: 4px 4px;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 5px 0px 0px 5px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0px 5px 5px 0px;
}

.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/*
 *  BADGE
 */

.ant-badge-count {
  background-color: @primary-color;
}

// COLLAPSE

.ant-collapse-borderless {
  background-color: #f0f2f5;
  border: 0;
}

// SWITCH

.ant-switch {
  background-image: linear-gradient(
      to right,
      fade(@black, 20%),
      fade(@black, 20%)
    ),
    linear-gradient(to right, #fff, #fff);
}

.ant-switch-checked {
  background: var(--primary-color);
}

// TABLE

.content-table .ant-table-thead,
.content-table .ant-table-tbody > tr:not(.ant-table-expanded-row) > td {
  background: white;
}

.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th,
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  position: unset !important;
  padding: 16px;
  overflow-wrap: break-word;
}

.ant-pagination-item-active a,
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: var(--primary-color);
}

.ant-pagination-item-active {
  background: #fff;
  border-color: var(--primary-color);
}

.ant-table-thead > tr > th {
  position: unset;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  position: relative;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  background: white;
  border-bottom: 1px solid #f0f0f0;
}

// TABS

.ant-tabs-tab-btn {
  padding: 0px 8px;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0px;
}

.vertical-tabs .ant-tabs-nav-wrap {
  width: 26ch;
}

.vertical-tabs > * > * > * > .ant-tabs-tab {
  overflow: hidden;
  text-wrap: wrap;
  text-align: start !important;
}

// ANT MODAL

.ant-modal-content {
  border-radius: 4px;
}

//HERE

.success-model--accept-text {
  text-align: center !important;
}

// li.sidebar__options__sub-option.ant-menu-item.ng-star-inserted {
//   white-space: break-spaces;
//   height: 100%;
//   line-height: 1.4;
//   padding: 10px 10px 10px 10px;
//   padding-left: 57px !important;
// }

.cae-select .ant-select-selector {
  height: 47px !important;
  align-items: center;
}

nz-select-top-control {
  overflow: auto;
}

//tabs
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 15px 15px 0 0;
  margin-right: 5px;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  background: #f1f2f6;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #233455;
  border-width: 2px;
}

.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  color: #233455;
}

/*checkboxes dynamic*/

.notchecked .ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(255, 81, 81) !important;
  border-color: red !important;
}

.checked .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #49ff49 !important;
}

.card-icon_color > i > svg > g > path {
  stroke: white !important;
}
