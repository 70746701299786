:root {
  --primary-11: #000000;
  --primary-10: #0A0F19;
  --primary-9: #121A2B;
  --primary-8: #172238;
  --primary-7: #1E2D4A;
  --primary-6: #233455;
  --primary-5: #344C7E;
  --primary-4: #4E71B7;
  --primary-3: #7591C7;
  --primary-2: #C4D0E7;
  --primary-1: #EBEFF7;

  --primary-color: var(--primary-6); // color used in main actions

  --text-color: black;
  --input-focus-shadow-color: #233455; // outer shadow of input on focus - GIVE 20% ALPHA

  --background-color: #f3f3f3;

  --menu-text-color: black;
  --menu-item-active-bg: #b7d8dd; // color of the side menu active ite
  --menu-bg: #f4f9fa; // color of the background of the menu
  --menu-sub-bg: #ffffff; // color of the background of the submenu

  --login-background-color: var(--primary-10);

  --header-logo-background: var(--primary-9);
  --header-background: var(--primary-color);
  --header-text-color: #fff;

  --footer-text-color: #fff;
  --footer-color: var(--primary-5);
}

@primary-11: #000000;
@primary-10: #0A0F19;
@primary-9: #121A2B;
@primary-8: #172238;
@primary-7: #1E2D4A;
@primary-6: #233455;
@primary-5: #344C7E;
@primary-4: #4E71B7;
@primary-3: #7591C7;
@primary-2: #C4D0E7;
@primary-1: #EBEFF7;

@primary-color: #233455;
@component-background: #ffffff;
@background-color-base: #ffffff;